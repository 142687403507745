import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, Navigate, RouterProvider, useLocation } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { appServicesToken, msalConfig, useLogin } from "./authConfig";
import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import Login from "./pages/login/Login";

const RequireAuth = ({ children }: { children: React.ReactNode }) => {
    let location = useLocation();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const isLoggedIn = (activeAccount || appServicesToken) != null;
    if (!isLoggedIn) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

var layout;
var login;
if (useLogin) {
    var msalInstance = new PublicClientApplication(msalConfig);

    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getActiveAccount());
    }

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback(event => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const account = event.payload as AccountInfo;
            msalInstance.setActiveAccount(account);
        }
    });

    layout = (
        <MsalProvider instance={msalInstance}>
            <RequireAuth>
                <Layout />
            </RequireAuth>
        </MsalProvider>
    );
    login = (
        <MsalProvider instance={msalInstance}>
            <Login />
        </MsalProvider>
    );
    login = (
        <MsalProvider instance={msalInstance}>
            <Login />
        </MsalProvider>
    );
} else {
    layout = <Layout />;
    login = <Login />;
}

initializeIcons();

const router = createHashRouter([
    {
        path: "/login",
        element: login
    },
    {
        path: "/",
        element: layout,
        children: [
            {
                path: "",
                element: <Chat />
            },
            {
                path: "qa",
                lazy: () => import("./pages/ask/Ask")
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
